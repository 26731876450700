import React, { useState } from 'react';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(
    process.env.NODE_ENV === 'production'
        ? 'pk_live_51L2EtOB9GPfX2KbMlFx3lbbefBMejfA2jKcsYc1ah0gKoiJ43bXJQvn0wA1lGo9Oqr6Z8xh7Yhb7xRa2vOf5UB7y00ulc5ium3'
        : 'pk_test_51L2EtOB9GPfX2KbMsMtTJvdkmjXJNASg95twvk6cBJKMxcFVBJzUWt89HoaeLoxk9AzyAIAMMvlWUkAv4GRjehkB00aNH9IevV'
);

// const stripePromise = loadStripe('pk_test_51L2EtOB9GPfX2KbMsMtTJvdkmjXJNASg95twvk6cBJKMxcFVBJzUWt89HoaeLoxk9AzyAIAMMvlWUkAv4GRjehkB00aNH9IevV');

const PaymentForm = () => {
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [amount, setAmount] = useState(1);
  const [subscriptionType, setSubscriptionType] = useState('payment');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Form submitted');
    setLoading(true);
    const endpoint =
      process.env.NODE_ENV === 'production'
        ? 'https://us-central1-onepercent-me.cloudfunctions.net/api/create-checkout-session'
        : 'http://127.0.0.1:5001/onepercent-me/us-central1/api/create-checkout-session';

    try {
      const result = await axios.post(endpoint, {
        amount,
        name,
        message,
        mode: subscriptionType,
      });

      if (result.data) {
        setLoading(false);
        // Redirect to Stripe Checkout
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: result.data.id,
        });

        if (error) {
          console.error('Stripe Checkout error:', error);
        }
      }
    } catch (error) {
      setLoading(false);
      alert('Something went wrong. Please try again later.');
      console.log(error)
    } finally {
      setLoading(false);
    }
  };

  const defaultAmounts = [1, 2, 5, 10];
  const isCustomAmount = amount === 1 || amount === 2 || amount === 5 || amount === 10;

  const handleSubscription = (type) => {
    setSubscriptionType(type);

    if (type === 'subscription' && amount > 10) {
      setAmount(10);
    }
  };

  return (
    <div className="bg-zinc-800 shadow-xl rounded-xl p-8 w-full mx-6 md:mx-0 md:w-5/12 xl:w-4/12">
      <h1 className="text-center mb-2 font-bold">1%.Me</h1>
      <form onSubmit={handleSubmit}>
        <input type="text" className="block w-full rounded-md p-2 mb-6 text-black" placeholder="Name" onChange={(e) => setName(e.target.value)} />
        <textarea
          rows="4"
          className="block w-full rounded-md p-2 mb-6 text-black"
          placeholder="Your message"
          resize="none"
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>

        <div className="grid grid-cols-2">
          <div>
            <label
              className={
                'p-3 border border-white rounded-l-md block text-center ' + (subscriptionType === 'payment' ? 'bg-white text-black' : 'text-white')
              }
              htmlFor="oneTime"
            >
              One time
            </label>
            <input type="radio" id="oneTime" className="invisible" onClick={() => handleSubscription('payment')} />
          </div>
          <div>
            <label
              className={
                'p-3 border border-white rounded-r-md block text-center ' +
                (subscriptionType === 'subscription' ? 'bg-white text-black' : 'text-white')
              }
              htmlFor="monthly"
            >
              Monthly
            </label>
            <input type="radio" id="monthly" className="invisible" onClick={() => handleSubscription('subscription')} />
          </div>
        </div>

        <div className="grid grid-cols-4 gap-2 mb-6 items-center">
          {defaultAmounts.map((val) => (
            <div key={val}>
              <label
                className={
                  'h-12 cursor-pointer flex items-center justify-center px-4 border border-white rounded-md text-center text-lg' +
                  (amount === val ? ' bg-white text-black' : '')
                }
                htmlFor={'val-' + val}
              >
                ${val}
              </label>
              <input type="radio" id={'val-' + val} className="invisible absolute" onClick={() => setAmount(val)} />
            </div>
          ))}
        </div>
        {subscriptionType === 'payment' ? (
          <div className="mb-6">
            <label htmlFor={'val-' + amount}>
              <input
                type="number"
                id={'val-' + amount}
                min="1"
                max="999999"
                className={
                  'w-full h-12 border border-white rounded-md text-center focus:bg-white focus:text-black' +
                  (!isCustomAmount ? ' text-black' : ' text-white')
                }
                placeholder="Custom amount"
                value={amount || 0}
                onClick={(e) => setAmount(e.target.value)}
                onChange={(e) => setAmount(e.target.value)}
              />
            </label>
          </div>
        ) : null}

        <button
          className="block w-full py-4 bg-indigo-700 hover:bg-indigo-900 transition-colors duration-150 rounded-md text-xl font-semibold"
          disabled={loading}
        >
          {loading ? 'Loading...' : `Donate ${'$' + amount} ${subscriptionType === 'payment' ? ' now' : ' monthly'} with Stripe`}
        </button>
      </form>
    </div>
  );
};

export default PaymentForm;
